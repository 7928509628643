body {
  margin: 0;
  padding: 0
}
.root{
  margin: 0;
  padding: 0;
}

:root {
  --swiper-theme-color: #725f24 !important;
}
.swiper-pagination-bullet{
  width: 16px !important;
  height: 16px !important;
  border: 2px solid white;
}

.swiper-container {
  height: 100%;
}
.swiper-button-prev{
  left: 0;
}
.swiper-button-next{
  right: 0;
}
@media screen and (max-width: 1440px){
  .swiper-container {
    height: auto;
  }
  .swiper-pagination-bullet{
    border: 1px solid white;
  }
}
@media screen and (max-width: 1024px){
  .swiper-container {
    height: auto;
  }
}
@media screen and (max-width: 700px){
  .swiper-slide-active{
    width: 100%;
  }
}


.f1wm87lx input[type="text" i], .f1wm87lx select {
    border: 1.5px solid;
    border-color: #c6af6b;
    height: 40px;
    width: 454px;
}
user agent stylesheet
input[type="text" i] {
    padding: 1px 2px;
}
user agent stylesheet
input:focus {
    outline-offset: 0px;
}

input:-internal-autofill-selected{ 
  background-color: #ffffff!important
}